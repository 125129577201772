import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import axios from '@axios'

export default function usersService() {
  const fetchUsers = async (queryParams) => {
    const res = await axios.get('/users', { params: queryParams })

    return res.data
  }

  const sendUsers = async (form) => {
    const res = await axios.post('/users', form)

    return res.data
  }

  const updateUsers = async (id, form) => {
    const res = await axios.post(`/users/${id}`, form)

    return res.data
  }

  const deleteUsers = async (id) => {
    const res = await axios.delete(`/users/${id}`)

    return res.data
  }

  const changerPassUser = async (form) => {
    const res = await axios.post('/change/user/password', form)

    return res.data
  }

  const statics = async (queryParams) => {
    const res = await axios.get('/users/statics', { params: queryParams })

    return res.data
  }

  return {
    statics,
    fetchUsers,
    sendUsers,
    updateUsers,
    deleteUsers,
    changerPassUser,
  }
}
